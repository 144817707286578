<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-8">
            <b-form-select
              v-model="filterPaymentState"
              :options="paymentStates"
              size="sm"
            >
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("PAYMENTS.SELECT_PAYMENT_STATE") }}
                </option>
              </template>
            </b-form-select>

            <CompactSuggestion
              v-model="selectedAccountId"
              size="sm"
              style="min-width: 30%"
              ref="accountDetailedSuggestionProcessorRef"
              suggestion-processor="accountDetailedSuggestionProcessor"
              :serializer="(s) => s.description"
              :placeholder="$t('PAYMENTS.ACCOUNT')"
            ></CompactSuggestion>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>

    <b-modal
      id="modal-restore-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_RESTORE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import Application from "../../application";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "payments",
  components: { CompactSuggestion },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "paymentSystemId",
          label: this.$t("PAYMENTS.PAYMENT_SYSTEM"),
          formatter: this.systemFormatter,
          sortingKey: "el.paymentSystem.id",
          sortable: true,
        },
        {
          key: "paymentStateId",
          label: this.$t("PAYMENTS.PAYMENT_STATE"),
          formatter: this.stateFormatter,
          sortingKey: "el.paymentState.id",
          sortable: true,
        },
        {
          key: "currencyName",
          label: this.$t("ACCOUNTS.CURRENCY"),
          sortingKey: "el.currency.id",
          sortable: false,
        },
        {
          key: "amount",
          label: this.$t("TRANSACTIONS.AMOUNT"),
          sortingKey: "el.amount",
          sortable: true,
        },
        {
          key: "fee",
          label: this.$t("PAYMENTS.FEE"),
          sortingKey: "el.fee",
          sortable: true,
        },
        {
          key: "registeredAmount",
          label: this.$t("PAYMENTS.REGISTERED_AMOUNT"),
          sortingKey: "el.registeredAmount",
          sortable: true,
        },
        {
          key: "totalAmount",
          label: this.$t("PAYMENTS.TOTAL_AMOUNT"),
          sortingKey: "el.totalAmount",
          sortable: true,
        },
      ],

      filterPaymentState: "",
      paymentStateRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.payment.model.PaymentState",
        requestType: "GET",
        requestParams: {},
      },
      paymentSystemRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.payment.model.PaymentSystem",
        requestType: "GET",
        requestParams: {},
      },
      paymentStates: [],

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/platform/api/payment",
        requestType: "GET",
        requestParams: { enrich: true },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      calculate: false,
      selectedItem: null,
      selectedAccountId: null,
      selectedItemState: null,

      constants: Application.constants,
      systemMap: {},
      stateMap: {},
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.PAYMENTS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("PAYMENTS.CANCEL_PAYMENT"),
        action: this.onCancelPaymentAction,
        disabled: function () {
          return (
            that.selectedItem === null ||
            that.selectedItem.paymentStateId !=
              that.constants.PAYMENT_STATES.ACTIVATED
          );
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("PAYMENTS.RESTORE_PAYMENT"),
        action: this.onRestorePaymentAction,
        disabled: function () {
          return (
            that.selectedItem === null ||
            that.selectedItem.paymentStateId !=
              that.constants.PAYMENT_STATES.CANCELED
          );
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
      {
        label: this.$t("ACTIONS.IMPORT"),
        action: this.onImportAction,
        icon: "fas fa-cloud-upload-alt",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadPaymentStates();
    this.loadPaymentSystems();
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadPaymentStates: function () {
      this.$store
        .dispatch(API_REQUEST, this.paymentStateRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.stateMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.paymentStates.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    loadPaymentSystems: function () {
      this.$store
        .dispatch(API_REQUEST, this.paymentSystemRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.systemMap[response[i].id] = response[i];
          }
        })
        .catch(this.onError);
    },

    onItemSelected: function (value) {
      this.selectedAccountId = value.description;
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "payment" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "account",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/platform/api/payment/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["paymentStateId"] =
        this.filterPaymentState.length > 0 ? this.filterPaymentState : null;
      this.getList.requestParams["accountId"] = this.selectedAccountId
        ? this.selectedAccountId
        : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["paymentStateId"];
      delete this.getList.requestParams["accountId"];
      this.filterPaymentState = "";
      this.selectedAccountId = null;
      this.suggestedVariants = [];
      this.$refs.accountDetailedSuggestionProcessorRef.clearValue();

      this.loadList();
    },

    onCancelPaymentAction: function () {
      this.$bvModal.show("modal-cancel-confirmation");
    },

    onCancelPayment: function () {
      const del = {
        resource: "/platform/api/payment/" + this.selectedItem.id + "/cancel",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast("Canceled successfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    onRestorePaymentAction: function () {
      this.$bvModal.show("modal-restore-confirmation");
    },

    onRestorePayment: function () {
      const del = {
        resource: "/platform/api/payment/" + this.selectedItem.id + "/restore",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast("Restored successfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    onImportAction: function () {
      // this.importFile.show = !this.importFile.show;
      this.promptFile().then(function (file) {
        document.querySelector("span").innerText =
          (file && file.name) || "no file selected";
      });
    },

    promptFile: function (contentType, multiple) {
      var input = document.createElement("input");
      input.type = "file";
      input.multiple = multiple;
      input.accept = contentType;
      return new Promise(function (resolve) {
        document.activeElement.onfocus = function () {
          document.activeElement.onfocus = null;
          setTimeout(resolve, 100);
        };
        input.onchange = function () {
          var files = Array.from(input.files);
          if (multiple) return resolve(files);
          resolve(files[0]);
        };
        input.click();
      });
    },

    systemFormatter: function (value) {
      if (value && value in this.systemMap)
        return this.systemMap[value].nameLocalized;

      return "";
    },
    stateFormatter: function (value) {
      if (value && value in this.stateMap)
        return this.stateMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
